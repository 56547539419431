import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { theme } from 'ui';
import { MargaretProvider, media } from '@tymate/margaret';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Footer from './Footer';
import Header from './Header';
import 'fontsource-montserrat';
import 'fontsource-montserrat/600.css';
import 'fontsource-montserrat/700.css';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.text};
    line-height: 1.5;
    background-color: ${({ theme }) => theme.background};
  }

  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    font-family: ${({ theme }) => theme.fonts.title};
  }

  img {
    display: block;
    max-width: 100%;
  }
`;

const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${media.tablet`
    padding-top: ${({ theme }) => theme.mainNav.height};
  `}
`;

const Main = styled.main`
  flex: 1;
`;

const Layout = ({ children, variant }) => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsGlobalInfo {
        adressNode {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  `);

  return (
    <MargaretProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <Site>
          <Header variant={variant} />
          <Main id="content">{children}</Main>
          <Footer globalInfo={data?.datoCmsGlobalInfo} />
        </Site>
      </ThemeProvider>
    </MargaretProvider>
  );
};

export default Layout;
